<template>
  <div class="container-fluid g-0">
    <div class="row">
      <div id="page-title" class="col-md-12">
        <h4 class="mt-3">Reports</h4>
      </div>
      <div class="col-md-12">
        <form id="report-filter" class="row d-flex align-items-center" @submit.prevent="filterReport">
          <div class="col-md-3 col-6 filter-attribute">
            <div class="div form-floating">
              <input v-model="fromDate" type="date" class="form-control" required />
              <label>From Date</label>
            </div>
          </div>
          <div class="col-md-3 col-6 filter-attribute">
            <div class="form-floating">
              <input v-model="toDate" type="date" class="form-control" required />
              <label>To Date</label>
            </div>
          </div>
          <div class="col-md-3 filter-attribute">
            <div class="form-floating">
              <select v-model="selectedProject" class="form-control" required>
                <option disabled value="">Please select one</option>
                <!-- <option value="all">All</option> -->
                <option v-for="project in projects" :key="project.id" :value="project.id">
                  {{ project.project }}
                </option>
              </select>
              <label>Project </label>
            </div>
          </div>
          <div class="col-md-3 col-12 filter-attribute">
            <div class="btn-group" role="group">
              <button type="submit" class="btn btn-primary">Filter</button>
              <!-- <button type="button" class="btn btn-primary">Invoice</button> -->
              <button type="button" class="btn btn-primary" @click="printPDF()">PDF</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-12" v-if="filteredEntries.length != 0">
        <div id="invoice-header" class="row">
          <div class="col-md-8 col-sm-8 col-8"></div>
          <div class="col-md-3 col-sm-3 col-3">Invoice</div>
          <div class="col-md-1 col-sm-1 col-1"></div>
        </div>
        <div class="row d-print-block">
          <div id="client-details" class="mt-3 col-md-6">
            <ul>
              <li><h2 class="fw-bold">Invoice to:</h2></li>
              <li>
                <h4 class="fw-bold">{{ projectDetails[0].client }}</h4>
              </li>
              <li>
                Address: {{ projectDetails[0].address }} {{ projectDetails[0].city }}, {{ projectDetails[0].country }}
              </li>
              <li>Email: {{ projectDetails[0].email }}</li>
              <li>Website: {{ projectDetails[0].website }}</li>
              <li>For period: {{ formatDate(fromDate) }} - {{ formatDate(toDate) }}</li>
            </ul>
          </div>
          <div id="personal-info" class="col-md-6">
            <div class="row mt-5 fw-bold">
              <ul class="list-unstyled mt-3">
                <li>
                  <h4 class="fw-bold">{{ `${user.data.firstName} ${user.data.lastName}` }}</h4>
                </li>
                <li>{{ user.data.emailAddress }}</li>
                <li>{{ user.data.website }}</li>
                <li>{{ `${user.data.address.street},` }}</li>
                <li>{{ `${user.data.address.zip} ${user.data.address.city} ` }}</li>
                <li>{{ getCountry }}</li>
              </ul>
            </div>
          </div>
        </div>
        <table class="table table-striped table-bordered mt-3">
          <thead>
            <tr class="d-flex table-dark">
              <th class="col-1 d-flex align-items-center">#</th>
              <th class="col-7 d-flex align-items-center">Title / Description</th>
              <th class="col-2 d-flex align-items-center">Date</th>
              <th class="col-2 d-flex align-items-center">Duration</th>
              <!-- <th class="col-1 d-flex align-items-center">Project</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, index) in filteredEntries" :key="entry.id" class="d-flex">
              <td class="col-1 d-flex align-items-center">{{ index + 1 }}</td>
              <td class="col-7 d-flex align-items-center">
                {{ entry.title ? entry.title : 'No Title' }} <br />
                {{ entry.description }}
              </td>
              <td class="col-2 d-flex align-items-center">
                {{ formatDate(entry.date) ? formatDate(entry.date) : 'n/a' }}
              </td>
              <td class="col-2 d-flex align-items-center">{{ getHmsFromSeconds(entry.duration) }}</td>
              <!-- <td class="col-1 d-flex align-items-center">{{ singleProjectInfo(entry.project).project }}</td> -->
            </tr>
          </tbody>
        </table>
        <div class="col-md-3 float-end text-end">
          <div class="fw-bold">
            Total hours:
            {{ `${projectCost.toFixed(2)} h` }}
          </div>
          <div class="fw-bold">
            Total cost:
            {{ selectedProject != 'all' ? projectTotalCost : 'n/a' }}
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">No result!</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { db } from '@/firebase/init';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { countries } from '@/assets/js/countries';
export default {
  name: 'Reports',
  data() {
    return {
      fromDate: '',
      toDate: '',
      selectedProject: '',
      filteredProject: '',
      filteredEntries: [],
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(['projects', 'user']),
    projectDetails() {
      return this.projects.filter((item) => item.id == this.selectedProject);
    },
    projectCost() {
      let sum = this.filteredEntries.reduce((acc, obj) => {
        return acc + obj.duration;
      }, 0);

      return (sum /= 3600);
    },
    projectTotalCost() {
      return (
        (this.projectCost * this.projectDetails[0].perHour).toFixed(2) + ' ' + this.projectDetails[0].perHourCurrency
      );
    },
    getCountry() {
      console.log(countries);
      const selected = countries.find((country) => country.code == this.user.data.address.country);
      return selected.name;
    },
  },
  methods: {
    ...mapActions(['getProjects']),
    singleProjectInfo(projectID) {
      return this.projects.find((item) => item.id == projectID);
    },
    async filterReport() {
      this.filteredProject = this.selectedProject;

      const uid = this.$store.state.user.auth.uid;

      const entriesRef = collection(db, `users/${uid}/entries`);

      if (this.filteredProject == 'all') {
        const q = query(
          entriesRef,
          where('date', '>=', this.fromDate),
          where('date', '<=', this.toDate),
          orderBy('date', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const entries = [];
        querySnapshot.forEach((doc) => {
          entries.push({ ...doc.data(), id: doc.id });
        });

        this.filteredEntries = entries;
      } else {
        const q = query(
          entriesRef,
          where('date', '>=', this.fromDate),
          where('date', '<=', this.toDate),
          where('project', '==', this.filteredProject),
          orderBy('date', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const entries = [];
        querySnapshot.forEach((doc) => {
          entries.push({ ...doc.data(), id: doc.id });
        });

        this.filteredEntries = entries;
      }
    },
    printPDF() {
      window.print();
    },
  },
  created() {
    this.getProjects();
  },
};
</script>

<style>
.filter-attribute .btn-group button {
  padding: 17px 25px;
}
div#client-details ul {
  list-style: none;
  padding: 0;
}

div#client-details ul li {
  line-height: 24px;
}
#personal-info ul {
  text-align: right;
}
#invoice-header {
  display: none;
  font-size: 60px;
  line-height: 66px;
  margin: 15px 0;
}
div#invoice-header > div:first-child,
div#invoice-header > div:last-child {
  background: #fdbd40;
  /* margin: 15px 0; */
}
div#invoice-header:nth-child(1) {
  text-align: center;
}
@media (max-width: 987px) {
  #report-filter .filter-attribute {
    margin-bottom: 15px;
  }
  #report-filter button {
    width: 100%;
  }
  div#personal-info ul {
    text-align: left !important;
  }
  #client-details {
    border-bottom: 1px solid #ccc;
    padding-bottom: 25px;
    margin-bottom: -25px;
  }
}
@media print {
  #sidebar-container,
  .filter-attribute,
  #page-title {
    display: none;
  }
  div#invoice-header {
    display: block;
    background: #fdbd40;
  }
  div#invoice-header .col-sm-8 {
    width: 60% !important;
    float: left;
    height: 60px;
    background: #fdbd40;
  }
  div#invoice-header .col-sm-3 {
    width: 30% !important;
    float: left;
  }
  div#invoice-header .col-sm-1 {
    width: 10% !important;
    float: left;
    height: 60px;
    background: #fdbd40;
  }
  #client-details {
    width: 65%;
    float: left;
  }
  #personal-info {
    width: 35%;
    float: right !important;
  }
  #personal-info ul {
    margin-top: 45px;
    text-align: right !important;
  }
}
</style>
